import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { getProperty } from "dot-prop";
import { Icon } from "@iconify/react";
import { convertPrice, getLabel } from "@utils";
import { ICaseResponseObject } from "@config/types";
import { TPropertyType } from "store/CreateCaseStore";
import { ambulanceMapper } from "@config/constant";

const CaseCard = (props: ICaseResponseObject) => {
  const bg = useColorModeValue("white", "slate.800");

  const getValue = (key: TPropertyType) => getProperty(props, key, "-");
  const selectedCard = getLabel(props.bookingType, props.orderStatus, props.orderId || "");
  const navigate = useNavigate();

  const handleClick = () => {
    if (selectedCard?.to) navigate(selectedCard?.to);
  };

  const subtype: any = getProperty(props, "entityRequired[0].subtype");

  return (
    <Box bg={bg} mb={4} shadow={"xs"} _hover={{ shadow: "md" }} p={2} borderRadius={"md"} onClick={handleClick} h={24}>
      <Flex justify={"space-between"} h={"full"}>
        <Flex direction={"column"} align={"start"} justify={"space-between"} h={"full"}>
          <Text fontWeight={"semibold"} fontSize={"sm"} color={"gray.500"}>
            {getValue("orderId")}
          </Text>
          <Text fontWeight={"normal"} fontSize={"sm"} color={"gray.500"}>
            {getValue("requestedFor.name")}
          </Text>
          <Flex alignItems={"center"} color={"purple.700"}>
            <Box mr={0}>
              <Icon icon={"tabler:currency-rupee"} fontSize={18} fontWeight={600} />
            </Box>
            <Text fontWeight={"black"} fontSize={"md"} color={"purple.700"}>
              {getValue("fareAgreed") === "-" ? "-" : convertPrice(getValue("fareAgreed") || 0)}
            </Text>
          </Flex>
        </Flex>

        <Flex direction={"column"} align={"end"} justify={"space-between"}>
          <Box bg={selectedCard?.bg} px={3} fontSize={"xs"} borderRadius={"full"} letterSpacing={"wide"} pt={0.5}>
            <Text color={selectedCard?.textColor} fontWeight={"900"}>
              {selectedCard?.text}
            </Text>
          </Box>
          <Box mr={2}>
            <Text fontSize={"sm"}>{subtype ? ambulanceMapper[subtype] : "-"}</Text>
          </Box>
          <Flex justify={"space-between"}>
            <Box fontSize={"sm"} fontWeight={"semibold"} mr={2} color={props.billToClient ? "green.600" : "grey.600"}>
              {props.billToClient ? "Hospital" : "Patient"}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CaseCard;
